<template>
  <section class="main-section shoutouts">
    <div class="content">
      <div class="table-wrap">
        <template v-if="activity !== null">
          <ht-sortable-table
            v-if="activity.length > 0"
            :rows="activity"
            :columns="[
              'Date',
              'Nominated by',
              'Nominee',
              'Nominated for',
              'Message',
            ]"
            :sortable="{
              '1': 'created_at',
              '2': 'created_by.name',
              '3': 'for_users.0.name',
              '4': 'nominated_award.name',
            }"
          >
            <template #rows="{rows, sort_by}">
              <tr
                v-for="(row, index) in rows"
                :key="`activity_row_${sort_by}_${index}`"
              >
                <td>
                  {{ $formatDate(row.created_at) }}
                </td>
                <td>
                  <user-card :user="row.created_by" :height="22" />
                </td>
                <td>
                  <user-card
                    v-for="for_user in row.for_users"
                    :key="`shoutout_${row.id}_foruser_${for_user.id}`"
                    :user="for_user"
                    :height="22"
                  />
                </td>
                <td>
                  {{ row.nominated_award.name }}
                </td>
                <td>{{ row.message_text }}</td>
              </tr>
            </template>
          </ht-sortable-table>
          <div class="empty-state" v-else>No nominations were found.</div>
        </template>
        <loading-indicator v-else />
      </div>
    </div>
  </section>
</template>

<script>
import { debounce } from 'lodash';

import UserGroupsFilter from '@/components/Reporting/Filters/UserGroups';
import ValuesFilter from '@/components/Reporting/Filters/Values';

export default {
  props: ['dateRange'],
  components: {
    UserGroupsFilter,
    ValuesFilter,
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      activity: null,
      filters: {
        campaign_id: null,
        date_range: this.dateRange,
      },
    };
  },
  watch: {
    dateRange(val) {
      this.filters.date_range = val;
    },
    filters: {
      deep: true,
      handler: function() {
        this.populateActivity();
      },
    },
  },
  beforeDestroy() {
    this.$parent.$off('exportTable', this.export);
  },
  created() {
    if (this.$route.query.campaign_id)
      this.filters.campaign_id = this.$route.query.campaign_id;

    this.populateActivity();

    this.$parent.$on('exportTable', this.export);
  },
  methods: {
    populateActivity: debounce(async function() {
      const resp = await this.$api.Reporting.get_nominations(this.filters);
      this.activity = resp;
    }, 250),
    filter_label(filter) {
      switch (filter) {
        case 'groups':
          return this.filters.user_groups.length > 0
            ? `Channels (${this.filters.user_groups.length})`
            : 'Channels';
        case 'values':
          return this.filters.values.length > 0
            ? `Values (${this.filters.values.length})`
            : 'Values';
      }
    },
    export() {
      const custom_fields = Object.entries(this.activity[0]).reduce(
        (agg, [key, val]) => {
          if (key.substr(0, 3) === 'CF_') agg.push(key.substr(3));
          return agg;
        },
        []
      );

      const custom_response_labels = this.activity.reduce((agg, nom) => {
        const fields = nom.nomination_campaign.custom_fields.reduce(
          (field_agg, field) => {
            field_agg[field.id] = field.label;
            return field_agg;
          },
          {}
        );

        return Object.assign({}, agg, fields);
      }, {});

      const custom_response_columns = Object.entries(custom_response_labels);

      const recipientColumnsEmails = [
        ...Array(
          this.activity.reduce(
            (total, row) =>
              row.for_users.length > total ? row.for_users.length : total,
            0
          )
        ).keys(),
      ].map((col) => `Nominee ${col + 1} (Email)`);
      const recipientColumnsNames = [
        ...Array(
          this.activity.reduce(
            (total, row) =>
              row.for_users.length > total ? row.for_users.length : total,
            0
          )
        ).keys(),
      ].map((col) => `Nominee ${col + 1} (Name)`);
      const recipientColumnsManagerEmail = [
        ...Array(
          this.activity.reduce(
            (total, row) =>
              row.for_users.length > total ? row.for_users.length : total,
            0
          )
        ).keys(),
      ].map((col) => `Nominee Manager ${col + 1} (Email)`);
      const recipientColumnsManagerNames = [
        ...Array(
          this.activity.reduce(
            (total, row) =>
              row.for_users.length > total ? row.for_users.length : total,
            0
          )
        ).keys(),
      ].map((col) => `Nominee Manager ${col + 1} (Name)`);

      window.generateCSV('HiThrive Nomination Report.csv', [
        [
          'Date',
          'Nominated by (Email)',
          'Nominated by (Name)',
          'Nominated by Manager (Email)',
          'Nominated by Manager (Name)',
          ...recipientColumnsEmails,
          ...recipientColumnsNames,
          ...recipientColumnsManagerEmail,
          ...recipientColumnsManagerNames,
          'Nominated for',
          'Message',
          ...custom_fields,
          ...custom_response_columns.map(([k, v]) => v),
        ],
        ..._.map(this.activity, (r) => {
          const custom_responses =
            'custom_field_responses' in r.metadata
              ? r.metadata.custom_field_responses
              : {};

          const return_array = [
            this.$csvDateFormat(r.created_at),
            r.created_by.email,
            r.created_by.name,
            r.created_by?.reports_to ? r.created_by.reports_to.email : '',
            r.created_by?.reports_to ? r.created_by.reports_to.name : '',
            ..._.map(recipientColumnsEmails, (col, recipientIndex) =>
              r.for_users[recipientIndex]
                ? r.for_users[recipientIndex].email
                : ''
            ),
            ..._.map(recipientColumnsNames, (col, recipientIndex) =>
              r.for_users[recipientIndex]
                ? r.for_users[recipientIndex].name
                : ''
            ),
            ..._.map(recipientColumnsManagerEmail, (col, recipientIndex) =>
              r.for_users[recipientIndex]?.reports_to
                ? r.for_users[recipientIndex].reports_to.email
                : ''
            ),
            ..._.map(recipientColumnsManagerNames, (col, recipientIndex) =>
              r.for_users[recipientIndex]?.reports_to
                ? r.for_users[recipientIndex].reports_to.name
                : ''
            ),
            r.nominated_award.name,
            r.message_text,
            ...Object.entries(r).reduce((agg, [k, v]) => {
              if (k.substr(0, 3) === 'CF_') agg.push(v);
              return agg;
            }, []),
          ];

          if (Object.entries(custom_responses).length > 0) {
            return_array.push(
              ...custom_response_columns
                .map(([k, v]) => k)
                .reduce((agg, field_id) => {
                  agg.push(custom_responses[field_id] || '');
                  return agg;
                }, [])
            );
          }

          return return_array;
        }),
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 0 0;
}
.table-wrap {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $light;

  > table {
    font-size: 14px;
    margin: 0;

    thead {
      th {
        border-top: none;
      }
    }
  }
}
</style>
