var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-section shoutouts"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"table-wrap"},[(_vm.activity !== null)?[(_vm.activity.length > 0)?_c('ht-sortable-table',{attrs:{"rows":_vm.activity,"columns":[
            'Date',
            'Nominated by',
            'Nominee',
            'Nominated for',
            'Message',
          ],"sortable":{
            '1': 'created_at',
            '2': 'created_by.name',
            '3': 'for_users.0.name',
            '4': 'nominated_award.name',
          }},scopedSlots:_vm._u([{key:"rows",fn:function({rows, sort_by}){return _vm._l((rows),function(row,index){return _c('tr',{key:`activity_row_${sort_by}_${index}`},[_c('td',[_vm._v(" "+_vm._s(_vm.$formatDate(row.created_at))+" ")]),_c('td',[_c('user-card',{attrs:{"user":row.created_by,"height":22}})],1),_c('td',_vm._l((row.for_users),function(for_user){return _c('user-card',{key:`shoutout_${row.id}_foruser_${for_user.id}`,attrs:{"user":for_user,"height":22}})}),1),_c('td',[_vm._v(" "+_vm._s(row.nominated_award.name)+" ")]),_c('td',[_vm._v(_vm._s(row.message_text))])])})}}],null,false,956393999)}):_c('div',{staticClass:"empty-state"},[_vm._v("No nominations were found.")])]:_c('loading-indicator')],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }